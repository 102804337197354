import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "2K+ WQHD Alarmserver Values",
  "path": "/Frequently_Asked_Question/WQHD_Alarmserver_Values/",
  "dateChanged": "2024-05-16",
  "author": "Mike Polinowski",
  "excerpt": "Can you please check the triggered alarms in MQTT?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='WQHD User Permission System' dateChanged='2024-05-16' author='Mike Polinowski' tag='INSTAR IP Camera' description='Can you please check the triggered alarms in MQTT?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_Alarmserver_Values/' locationFR='/fr/Frequently_Asked_Question/WQHD_Alarmserver_Values/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "2k-wqhd-mqtt--http-alarm-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#2k-wqhd-mqtt--http-alarm-server",
        "aria-label": "2k wqhd mqtt  http alarm server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2K+ WQHD MQTT & HTTP Alarm server`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Can you please check the triggered alarms in MQTT?`}</p>
    <p>{`The following problem:`}</p>
    <ul>
      <li parentName="ul">{`If I have activated the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/"
        }}>{`Alarm areas`}</a>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1`}</code>{` & `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`2`}</code>{`, the alarm in area `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1`}</code>{` is reported by the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
        }}>{`Alarm server`}</a>{` when entering area `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`2`}</code>{`.`}</li>
      <li parentName="ul">{`If I have areas `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`2`}</code>{` & `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`3`}</code>{` active and enter area `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`3`}</code>{`, area `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`2`}</code>{` is triggered according to the MQTT alarm server.`}</li>
    </ul>
    <p>{`So actually always the “lowest” trigger. However, if I test the corresponding `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/"
      }}>{`detection area manually`}</a>{`, the correct trigger is also reported by the alarm server.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: That is correct - alarm triggers are briefly collected internally. If several triggers occur in this time window, you receive summarized event in the system log:`}</p>
    <blockquote>
      <p parentName="blockquote">{`2024-05-16 7:23:47: A: Motion area triggered `}{`[1,2,3,4]`}</p>
    </blockquote>
    <p>{`This allows us to offer filters - for example, only trigger an alarm if the PIR and a specific detection area was triggered.`}</p>
    <p>{`However, if a person triggers only one area, this array also contains only this one entry - like here, for example:`}</p>
    <blockquote>
      <p parentName="blockquote">{`2024-05-16 7:11:29: A: Motion area triggered `}{`[2]`}</p>
    </blockquote>
    <p>{`The first element of this array is used for the alarm server. I.e. if you divide the frame into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`4`}</code>{` vertical alarm areas and a person walks slowly from one side to the other, you will receive triggers from all four areas from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[1]`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[4]`}</code>{`. Although internally the first entry may be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[1,2,3]`}</code>{` you will receive a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` from the alarm server. This is followed e.g. by an entry `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[2,3,4]`}</code>{` and you receive a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2`}</code>{` and so on. If the person walks quickly through the frame, you may only receive a single trigger for area 1, as all 4 areas trigger once, but these triggers are combined into one event `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[1,2,3,4]`}</code>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      